
import React from 'react';
import { Box, Group } from "@mantine/core";
import IdeasTabs from '../IdeasTabs';

interface HeaderProps {
  savedViews: any[];
  selectedView: any;
  setSelectedView: (value: any) => void;
  upsertView: (body: any, callback: (res: any) => void) => void;
  warpWidth: number;
  actionIconsWidth: number;
  
}

const Header: React.FC<HeaderProps> = ({
  savedViews,
  selectedView,
  setSelectedView,
  upsertView,
  warpWidth,
  actionIconsWidth,

}) => (
  <Group
    noWrap
    mx="sm"
    mb="md"
    position="apart"
    sx={{ borderBottom: "1px solid #F1F1F1", whiteSpace: "nowrap" }}
  >
    <Box pb="0" sx={{ whiteSpace: "nowrap" }}>
      <IdeasTabs
        savedViews={savedViews}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        upsertViews={upsertView}
        SplitOn={Math.floor((warpWidth - actionIconsWidth) / 150)}
      />
    </Box>
  </Group>
);

export default Header;
