import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Group, Title, Text, Loader, Stack } from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useElementSize,
} from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { canEditTeamIds } from "../../Api";
import {
  buildQueryString,
  getFilteredRequests as fetchFilteredRequests,
  starClicked as handleStarClicked,
  loadListMembers,
} from "./ideasHelpers";

import { getFixedDateRanges } from "./filters/FixedDateRanges";
import { noTrendFilterSpec } from "./filters/FiltersSpec";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";
import SyncContext from "../../context/SyncContext";
import Header from "./components/Header";
import FilterActions from "./components/FilterActions";
import IdeasContext, { IdeasContextProvider } from "./IdeasContext";
import FiltersContext, { FiltersContextProvider } from "./filters/context/FiltersContext";
import { useNavigate } from "react-router-dom";
import IdeasTable from "./Table/IdeasTable";



const PAGE_SIZE = 50;

export const Ideas = () => {
  const { ref } = useElementSize();
  const { ref: warpRef, width: warpWidth } = useElementSize();
  const { ref: actionIconsRef, width: actionIconsWidth } = useElementSize();

  const { ideasFiltersConfig} =
    useContext(SyncContext);

  const {
    page,
    sortStatus,
    savedViews,
    selectedView,
    requestsTotalCount,
    setRequestsTotalCount,
    setPage,
    setSortStatus,
    setSelectedView,
    loadViews,
    upsertView,
    sortState, 
    ideaColumns  = []
  } = useContext(IdeasContext);

  /* console.log('sortStatus')
  console.log(sortStatus) */

  const {
    activeFilters,
    setActiveFilters,
    setCheckedComponents
  } = useContext(FiltersContext);
  // console.log({activeFilters, checkedComponents})
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any[]>([]);
  const [ideas, setIdeas] = useState<any[]>([]);
  const [loadingStar, setLoadingStar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const [loadingCols, setLoadingCols] = useState(true);
  const [searchOpened, setSearchOpened] = useState(false);
  const [isAllowedToEditDates, setIsAllowedToEditDates] = useState<boolean>(true);
  const [opened, { open, close: doClose }] = useDisclosure(false);
  const [viewName, setViewName] = useState("");
  const [menuFiltersObj, setMenuFiltersObj] = useState<any>(noTrendFilterSpec);
  const [, setLastFilterParams] = useState<any>({});

  const [filterRowOpened, { toggle }] = useDisclosure(true);
  const getFilteredRequests = () => {
  
    fetchFilteredRequests(
      sortStatus,
      setLastFilterParams,
      page,
      search,
      activeFilters,
      setLoading,
      setIdeas,
      setRequestsTotalCount, 
      setPage,
      ideasFiltersConfig,
      auth0
    );
  };

  const starClicked = (_id: string, bool: boolean) => {
    handleStarClicked(
      _id,
      bool,
      setLoadingStar,
      {
        id: _id,
        push: bool,
      },
      setIdeas,
      auth0
    );
  };

  const handleSaveViews = () => {
    upsertView({ name: viewName, filters: activeFilters }, (res) => {

      setSelectedView({
        _id: res?.data?.updateOneResult?.upsertedId,
        name: viewName,
        filters: activeFilters,
      });
      
      showNotification({
        title: "Saved successfully!",
        color: "teal",
        message: "view saved successfully",
        icon: <IconCheck size="1.1rem" />,
        autoClose: 1200
      });
      doClose();
    });
  };

  const loadCanEditTeamIds = () => {
    canEditTeamIds(auth0)
      .then((customizations: any) => {
        const name =
          customizations && customizations.length > 0
            ? customizations[0].name
            : undefined;
        if (name === "committedDate") {
          setIsAllowedToEditDates(true);
        } else {
          setIsAllowedToEditDates(false);
        }
      })

      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setMenuFiltersObj(ideasFiltersConfig)
  }, [ideasFiltersConfig]);


  useEffect(() => {
    getFilteredRequests();
  }, [activeFilters, page, sortStatus, debouncedSearch]);

  useEffect(() => {
    if (selectedView?.filters) {
      const _filters = selectedView?.filters.map((filter: any) =>
        filter.type == "date" && filter.operator
          ? {
            ...filter,
            selected: getFixedDateRanges[filter.operator].values,
            // name: selectedView.name,
          }
          : {
            ...filter,
            // name: selectedView.name,
          }
      );
      setActiveFilters(_filters);
    }
    setPage('1');
  }, [selectedView]);

  useEffect(() => {
    loadCanEditTeamIds();
    loadListMembers(setMenuFiltersObj, auth0);
    loadViews();
    const queryParams = new URLSearchParams(location.search);
    const filtersStr = queryParams.get("filters");
    const filters = filtersStr ? JSON.parse(decodeURIComponent(filtersStr)) : undefined;    
    if (filters) {
      setActiveFilters(filters);
      setCheckedComponents((prev) => ({
        ...prev,
        ...filters.reduce((acc:any, filter:any) => ({
          ...acc,
          [filter.parent.toLowerCase()]: filter?.selected?.map((i:string) => i.toLowerCase())
        }), {}),
      }));
    }

  }, []);


  useEffect(() => {
    const newQueryString = buildQueryString(page || "1", activeFilters, selectedView, sortStatus);
    navigate(`/?${newQueryString}`, { replace: true });
  }, [page, activeFilters, sortStatus, selectedView, navigate, buildQueryString]);


  useEffect(() => {
    if (ideaColumns && ideaColumns?.length > 0) {

      
      setLoadingCols(false);
    }
  }, [ideaColumns]);

  

  return (

    <Box>
      {
        <Box bg={"#fff"} ref={ref} /* w='95vw' */>
          <Box mt={20} mx={20}>
            <Text fz='20px' fw={600} ml={18} mb={13}>Ideas</Text>
            {/* Header */}
            <Box ref={warpRef}>
              <Header
                savedViews={savedViews}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                upsertView={upsertView}
                warpWidth={warpWidth}
                actionIconsWidth={actionIconsWidth}
              />
            </Box>
            {/* Filter Actions */}
            {filterRowOpened && (
              <Box /* py={1} */>

                <FilterActions
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  selectedView={selectedView}
                  open={open}
                  handleSaveViews={handleSaveViews}
                  opened={opened}
                  viewName={viewName}
                  setViewName={setViewName}
                  doClose={doClose}
                  toggle={toggle}
                  search={search}
                  setSearch={setSearch}
                  searchOpened={searchOpened}
                  setSearchOpened={setSearchOpened}
                  actionIconsRef={actionIconsRef}
                />
              </Box>

              
            )}

            {/* Main Table Display */}
            <Box /* sx={{ height: `calc(100vh - ${filterRowOpened ? 223 : 181}px)` }} */ >
              {/* sortStatus?.columnAccessor &&  */!loadingCols ? (
                <IdeasTable
                  ideaColumns={ideaColumns} 
                  ideas={ideas} 
                  isAllowedToEditDates={isAllowedToEditDates}
                  handlePageChange={(newPage) => setPage(newPage)}
                  totalRecords={requestsTotalCount}
                  recordsPerPage={PAGE_SIZE}
                  page={(Number(page) || 1)}
                  sortStatus={sortStatus}
                  setSortStatus={setSortStatus}
                  loading={loading}
                  PAGE_SIZE={PAGE_SIZE}
                
                />
              ) : <Stack h='66vh' w='88vw' align="center" justify="center">
                <Loader/>
              </Stack>}
            </Box>
          </Box>
        </Box>
        
      }
    </Box>
  );
};


export const IdeasWraper = () => (
  <IdeasContextProvider>
    <FiltersContextProvider>
      <Ideas/>
    </FiltersContextProvider>
  </IdeasContextProvider>
)