import {
  TextInput,
  Box,
  ScrollArea,
  Popover,
  Card,
  Group,
  Flex,
  Text,
  Spoiler
} from "@mantine/core";
import {IconMessage, IconSearch} from "@tabler/icons";
import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {useClickOutside, useHover} from "@mantine/hooks";
import { searchIdeas } from "../../../Api";

const Search = () => {
  const auth0 = useAuth0();
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));

  const navigate = useNavigate();
  const doSearch = async () => {
    searchIdeas(query, auth0)
      .then((results: any[]) => {
        setSearchResults(results || []);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        doSearch();
      } else {
        setSearchResults([]);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  return (
    <Popover opened={opened} width={600}>
      <Popover.Target>
        <TextInput
          sx={{
            input: {
              background: "#363699",
              color: "#fff",
            },
          }}
          placeholder="Search"
          variant="filled"
          radius="md"
          icon={<IconSearch size={14.5} />}
          onFocus={() => setOpened(true)}
          onClick={() => setOpened(true)}
          onChange={(event) => setQuery(event.currentTarget.value)}
        />
      </Popover.Target>
      <Popover.Dropdown ml={"100px"} p={0} m={0} >
        <Box ref={ref}>
          <ScrollArea
            style={{ height: 300, overflowX: "unset" }}
            px={"md"}
            pt={"sm"}
            pb={0}
          >
            {searchResults.map((idea: any) => (
              <IdeaSearchResult
                idea={idea}
                onClick={() => {
                  navigate(`/idea/${idea._id}`);
                  setOpened(false);
                }}
              />
            ))}
          </ScrollArea>
          <Box fz="14px" sx={{ borderTop: "1px solid #D9D9D9" }} p={"md"}>
            {" "}
            {searchResults.length} results found{" "}
          </Box>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};


function IdeaSearchResult({
  idea,
  onClick,
}: {
  idea: any;
  onClick: () => void;
}) {
  const { hovered, ref } = useHover();
  return (
    <Card
      ref={ref}
      withBorder
      p="xs"
      my="xs"
      sx={{
        ":hover": {
          backgroundColor: "#5C5CEA33",
          color: "black",
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <Group position="apart">
        <Flex align="center" h={26}>
          <IconMessage color="#5B60E3" />
          <Box ml="sm" mr="sm">
            <Text w='470px' lineClamp={1} fz="sm">
              {idea?.title || "Unassigned"}
            </Text>
          </Box>
        </Flex>
      </Group>

      <Box w='530px'>
        <Spoiler
          maxHeight={20}
          showLabel="see more"
          hideLabel="Hide"
          sx={(theme) => ({
            fontSize: theme.fontSizes.sm,
            button: {
              color: "#5B60E3",
              fontSize: theme.fontSizes.sm,
            },
          })}
          my="xs"
        >
          {idea?.description?.replace(/<[^>]*>/g, "")}
        </Spoiler>
      </Box>
      <Flex align="center" justify="space-between">
        <Box mr="sm">
          <Text fz="xs" c="dimmed">
              Owner
          </Text>
          <Text  fz="xs" fw={500} tt="capitalize" >
            {idea?.owner?.name || '-'}
          </Text>
        </Box>
        <Box mr="sm">
          <Text fz="xs" c="dimmed">
              Status
          </Text>
          <Text fz="xs" fw={500} tt="capitalize">
            {idea?.status}
          </Text>
        </Box>
        <Box mr="sm">
          <Text fz="xs" c="dimmed">
              Priority
          </Text>
          <Text fz="xs" fw={500} tt="capitalize">
            {idea?.priority || "-"}
          </Text>
        </Box>
        <Box mr="sm">
          <Text fz="xs" c="dimmed">
              Product area
          </Text>
          <Text fz="xs" fw={500} tt="capitalize">
            {idea?.componentObj?.name|| "-"}
          </Text>
        </Box>
        <Box mr="sm">
          <Text fz="xs" c="dimmed">
              Accounts
          </Text>
          <Text fz="xs" fw={500}>
            {idea?.aggregations?.accounts?.count || "-"}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}


export default Search;
