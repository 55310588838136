import {
  Box,
  Group
} from "@mantine/core";
import { ViewsMenu } from "./filters/ViewsMenu";

import IdeasTab from "./IdeasTab";

const IdeasTabs = ({
  savedViews,
  selectedView,
  setSelectedView,
  upsertViews,
  SplitOn
}: {
  savedViews: any;
  selectedView: any;
  setSelectedView: (value: any) => void;
  upsertViews: (body: any, func: (res: any) => void) => void;
  SplitOn: number
}) => {
  return (
    <Box>
      <Group spacing={0}>
        {savedViews.slice(0, SplitOn).map((view: any) => (
          <IdeasTab
            key={view._id}
            view={view}
            isActive={view._id == selectedView._id}
            onTabChange={(item_id: string) => {
              setSelectedView(
                savedViews.filter((view: any) => view._id == item_id)[0]
              );
            }}
            upsertViews={upsertViews}
          />
        ))}
        {savedViews.slice(SplitOn).length > 0 && <ViewsMenu 
          savedViews={savedViews.slice(SplitOn)}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          upsertViews={upsertViews}
        />}
      </Group>
    </Box>
  );
};

export default IdeasTabs;
