import { AppShell } from "@mantine/core";
import { MainNav } from "./MainNav";
import { MainHeader } from "./Header/MainHeader";
import { ModalsProvider } from "@mantine/modals";


export const MainLayout = (props: any) => (
  <AppShell padding={0} navbar={<MainNav />} header={<MainHeader />}>
    <ModalsProvider labels={{ confirm: "Submit", cancel: "Cancel" }}>
      {props.children}
    </ModalsProvider>
  </AppShell>
);
