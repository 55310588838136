import { IconSearch } from "@tabler/icons"
import Search from "./Search"

const HeaderSearch = ({hovered}:{hovered: boolean}) => {
  return (
    <>

      {hovered ?
        <Search /> : <IconSearch/>
      }
    </>
  )
}

export default HeaderSearch