import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Table,
  Modal,
  TextInput,
  ColorInput,
  ActionIcon,
  Group,
  Tooltip,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { getTagsList, createTag, updateTag, deleteTag } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SyncContext from "../../context/SyncContext";

interface Tag {
  _id: string;
  label: string;
  key: string;
  color: string;
  index: number;
  createdAt: string;
  updatedAt: string;
}

const Tbody = React.forwardRef<HTMLTableSectionElement, any>(
  ({ children, ...props }, ref) => (
    <tbody ref={ref} {...props}>
      {children}
    </tbody>
  )
);

const Tr = React.forwardRef<HTMLTableRowElement, any>(
  ({ children, ...props }, ref) => (
    <tr ref={ref} {...props}>
      {children}
    </tr>
  )
);

const TagsManager: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingTag, setEditingTag] = useState<Tag | null>(null);
  const { fetchTags, tagsList: tags } = useContext(SyncContext);

  const auth0 = useAuth0();

  const form = useForm({
    initialValues: {
      label: "",
      color: "#ffffff",
    },

    validate: {
      label: (value) => (value ? null : "Label is required"),
      color: (value) => (value ? null : "Color is required"),
    },
  });


  useEffect(() => {
    fetchTags();
  }, []);

  const handleCreateTag = () => {
    setEditingTag(null);
    form.reset();
    setModalOpen(true);
  };

  const handleEditTag = (tag: Tag) => {
    setEditingTag(tag);
    form.setValues({
      label: tag.label,
      color: tag.color,
    });
    setModalOpen(true);
  };

  const handleDeleteTag = async (tagId: string) => {
    if (window.confirm("Are you sure you want to delete this tag?")) {
      try {
        await deleteTag(tagId, auth0);
        fetchTags();
      } catch (error) {
        console.error("Error deleting tag:", error);
      }
    }
  };

  const handleSubmit = async (values: typeof form.values) => {
    try {
      if (editingTag) {
        // Update tag
        await updateTag(editingTag._id, values, auth0);
        fetchTags();
      } else {
        // Create new tag with the next available index
        await createTag(
          { ...values, index: tags.length },
          auth0
        );
        fetchTags();
      }
      setModalOpen(false);
    } catch (error: any) {
      console.error("Error saving tag:", error);
    }
  };

  const handleDragEnd = async (result:any) => {
    if (!result.destination) {
      return;
    }

    const reorderedTags = Array.from(tags);
    const [movedTag] = reorderedTags.splice(result.source.index, 1);
    reorderedTags.splice(result.destination.index, 0, movedTag);

    // Update the index of each tag
    const updatedTags = reorderedTags.map((tag, index) => ({
      ...tag,
      index,
    }));

    // Update the indices in the backend
    try {
      for (const tag of updatedTags) {
        await updateTag(tag._id, { index: tag.index }, auth0);
        await fetchTags();
      }
    } catch (error) {
      console.error("Error updating tag indices:", error);
    }
  };

  return (
    <Box px={"xl"}>
      <Group position="apart" mb="md">
        <h2>Manage Tags</h2>
        <Button
          leftIcon={<IconPlus />}
          onClick={handleCreateTag}
          bg={"#5C5CEA"}
          sx={{
            fontWeight: 700,
            ":hover": { backgroundColor: "#9f9af8" },
            "&[disabled]": {
              pointerEvents: "all",
              backgroundColor: "#fff",
              cursor: "not-allowed",
              ":hover": {
                backgroundColor: "#fff",
              },
            },
          }}
        >
          New Tag
        </Button>
      </Group>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Table
          highlightOnHover
          withBorder
          sx={{
            tableLayout: 'fixed', // Use fixed table layout
            width: '100%', // Ensure the table takes full width
            borderRadius: 8,
            'th': { 
              width: '25%', // Adjust percentages based on the number of columns
              textAlign: 'left',
            },
            'td': { 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap',
            },
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f3f3f3" }}>
              <th>Label</th>
              <th>Key</th>
              <th>Color</th>
              <th style={{ textAlign: "right" }}>Actions</th>
            </tr>
          </thead>
          {tags.length > 0 && <Droppable droppableId="droppable" type="group">
            {(provided) => (
              <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                {tags.map((tag, index) => (
                  <Draggable key={tag.key} draggableId={tag.key} index={index}>
                    {(provided) => (
                      <Tr
                        ref={provided.innerRef}
                        style={{
                          width: "700px"
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{tag.label}</td>
                        <td>{tag.key}</td>
                        <td>
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              backgroundColor: tag.color,
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td
                          style={{ textAlign: "right", justifyItems: "right" }}
                        >
                          <Group spacing="xs">
                            <Tooltip label="Edit">
                              <ActionIcon
                                color="blue"
                                onClick={() => handleEditTag(tag)}
                              >
                                <IconPencil size={16} />
                              </ActionIcon>
                            </Tooltip>
                            <Tooltip label="Delete">
                              <ActionIcon
                                color="red"
                                onClick={() => handleDeleteTag(tag._id)}
                              >
                                <IconTrash size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Group>
                        </td>
                      </Tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Tbody>
            )}
          </Droppable>}
        </Table>
      </DragDropContext>

      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title={editingTag ? "Edit Tag" : "Create Tag"}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Label"
            placeholder="Enter tag label"
            {...form.getInputProps("label")}
            required
            mb="sm"
          />
          <ColorInput
            label="Color"
            placeholder="Pick a color"
            swatches={[
              "#25262b",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
            {...form.getInputProps("color")}
            required
            mb="sm"
          />
          <Group position="right" mt="md">
            <Button variant="default" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">{editingTag ? "Update" : "Create"}</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default TagsManager;