import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { addViews, getViews } from "../../Api";
import { DataTableSortStatus } from "mantine-datatable";
import SyncContext from "../../context/SyncContext";
import { updateAccessorByState } from "../../utils";
import { TextCell } from "./components/TableCells/TextCell";
import { OwnerCell } from "./components/TableCells/OwnerCell";
import { PriorityCell } from "./components/TableCells/PriorityCell";
import { TemplateCell } from "./components/TableCells/TemplateCell";
import { CommitmentsCell } from "./components/TableCells/CommitmentsCell";
import StarCell from "./components/TableCells/StarCell";
import { DynamicCell } from "./components/TableCells/DynamicCell";
import NewRequestsCell from "./components/TableCells/NewRequestsCell";
import { TagsCell } from "./components/TableCells/TagsCell";
import { Tooltip , Text} from "@mantine/core";

type IdeasContextType = {
  location: any;
  page: string | null;
  filters: any;
  sortStatus: any;
  savedViews: any[];
  selectedView: any;
  requestsTotalCount: number;
  setRequestsTotalCount: React.Dispatch<React.SetStateAction<number>>
  setPage: React.Dispatch<React.SetStateAction<string | null>>;
  setFilters: React.Dispatch<React.SetStateAction<string | null>>;
  setSortStatus:React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
  setSelectedView: React.Dispatch<React.SetStateAction<any>>;
  loadViews: (deleted?: boolean) => void;
  upsertView: (body: any, callback: (res: any) => void) => void;
  ideaColumns: any[];
  sortState: string;
  setSortState: React.Dispatch<React.SetStateAction<string>>;
};

const initialState: IdeasContextType = {
  location: null,
  page: null,
  filters: null,
  sortStatus: null,
  savedViews: [],
  selectedView: {},
  requestsTotalCount: 0,
  setRequestsTotalCount: () => null,
  setPage: () => null,
  setFilters: () => null,
  setSortStatus: () => null,
  setSelectedView: () => null,
  loadViews: () => null,
  upsertView: () => null,
  ideaColumns: [],
  sortState: '',
  setSortState: () => null
};

const IdeasContext = createContext<IdeasContextType>(initialState);

const IdeasContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    requestPriorities,
    ideaPriorities,
    displayState,
    ideasTableDisplay,
    customEvidenceObj,
  } = useContext(SyncContext);

  const {
    ideaColumns 
  } = useContext(SyncContext);


  /* console.log('ideaColumns.length')
 
  console.log(ideaColumns.length) */

  const location = useLocation();
  const auth0 = useAuth0();

  const queryParams = new URLSearchParams(location.search);
  const _page = queryParams.get("page");
  // const _filters = queryParams.get("filters");
  const _selectedView = queryParams.get("selectedView");
  const _sortStatus = queryParams.get("sortStatus");

  const [page, setPage] = useState<string | null>(_page || null);
  const [requestsTotalCount, setRequestsTotalCount] = useState(0);
  /* const [columnsTest, setColumnsTest] = useState<any[]>([]); */
  const [sortState, setSortState] = useState<string>('total');
  const [filters, setFilters] = useState<any>(() => {
    const queryParams = new URLSearchParams(location.search);
    const filtersStr = queryParams.get("filters");
    return filtersStr ? JSON.parse(decodeURIComponent(filtersStr)) : null;
  });

  const [sortStatus, setSortStatus] = useState<any>(
    _sortStatus ? JSON.parse(_sortStatus) :  { columnAccessor: "updatedAt", direction: "asc" } /* { columnAccessor: null, direction: 'none' } */
  );

  // const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>(
  //   _filters ? JSON.parse(_filters) : []
  // );

  const [savedViews, setSavedViews] = useState<any[]>([]);

  const [selectedView, setSelectedView] = useState<any>(
    _selectedView ? JSON.parse(decodeURIComponent(_selectedView)) : {}
  );



  const loadViews = async (deleted = false) => {
    try {
      const { views } = await getViews(auth0);
      setSavedViews(views);
      if (!selectedView?._id || deleted) {
        setSelectedView(views[0]);
      }
    } catch (error) {
      console.error("Failed to load views:", error);
    }
  };

  const upsertView = async (body: any, callback: (res: any) => void) => {
    try {
      const res = await addViews(body, auth0);
      callback(res);
      await loadViews(body?.deleted);
    } catch (error) {
      console.error("Failed to upsert view:", error);
    }
  };

  useEffect(() => {
    loadViews();
  }, []);



  return (
    <IdeasContext.Provider
      value={{
        location,
        page,
        filters,
        sortStatus,
        savedViews,
        selectedView,
        requestsTotalCount,
        setRequestsTotalCount,
        setPage,
        setFilters,
        setSortStatus,
        setSelectedView,
        loadViews,
        upsertView,
        ideaColumns,
        sortState, 
        setSortState
      }}
    >
      {children}
    </IdeasContext.Provider>
  );
};

export { IdeasContext, IdeasContextProvider };
export default IdeasContext;
