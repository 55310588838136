import React, { useContext, useEffect, useState, useRef } from "react";
import { Checkbox, Menu, Text, UnstyledButton, Group } from "@mantine/core";
import { saveIdea } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../context/SyncContext";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";

export const TagsCell: React.FC<any> = ({ data, col, renderParams }) => {
  const { tagsOptions } = useContext(SyncContext);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialTags, setInitialTags] = useState<string[]>([]);
  const [visibleTags, setVisibleTags] = useState<any[]>([]);
  const [overflowCount, setOverflowCount] = useState<number>(0);
  const targetRef = useRef<HTMLDivElement>(null);

  const auth0 = useAuth0();

  useEffect(() => {
    const value = getNestedFieldValue(data, col.accessor) || [];

    setSelectedTags(value);
    setInitialTags(value);
  }, [data, col.accessor]);

  useEffect(() => {
    const updateVisibleTags = () => {
      if (!targetRef.current) return;

      const containerWidth = targetRef.current.offsetWidth;
      let totalWidth = 0;
      const visible: any[] = [];
      let overflow = 0;

      selectedTags.forEach((tagId) => {
        const tag = tagsOptions.find((tag) => tag.value === tagId);
        if (tag) {
          const tagWidth = tag.label.length * 8 + 24;
          if (totalWidth + tagWidth <= containerWidth) {
            visible.push(tag);
            totalWidth += tagWidth;
          } else {
            overflow += 1;
          }
        }
      });

      setVisibleTags(visible);
      setOverflowCount(overflow);
    };

    updateVisibleTags();
    window.addEventListener("resize", updateVisibleTags);
    return () => window.removeEventListener("resize", updateVisibleTags);
  }, [selectedTags, tagsOptions]);

  const handleTagToggle = async (tagId: string) => {
    let updatedTags;
    if (selectedTags.includes(tagId)) {
      updatedTags = selectedTags.filter((id) => id !== tagId);
    } else {
      updatedTags = [...selectedTags, tagId];
    }

    setSelectedTags(updatedTags);

    if (JSON.stringify(updatedTags) !== JSON.stringify(initialTags)) {
      try {
        await saveIdea({ id: data._id, [col.accessor]: updatedTags }, auth0);
        setInitialTags(updatedTags);
      } catch (error) {
        console.error("Error updating idea:", error);
      }
    }
  };

  return (
    <Menu position="bottom-start" shadow="sm">
      <Menu.Target ref={targetRef}>
        <UnstyledButton w={"-webkit-fill-available"}>
          <Group spacing={4} noWrap>
            {visibleTags.map((tag) => (
              <Text
                key={tag.value}
                size="sm"
                style={{
                  backgroundColor: `${tag.color}50`,
                  color: tag.color,
                  padding: "0px 4px",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                {tag.label}
              </Text>
            ))}
            {overflowCount > 0 && (
              <Text
                size="sm"
                style={{
                  color: "#5C5CEB",
                  marginLeft: 4,
                }}
              >
                +{overflowCount}
              </Text>
            )}
            {initialTags.length == 0 && selectedTags.length == 0 && (
              <Text size="sm" color="#aeaeae">
                Select {col?.title?.toLowerCase()}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown miw={200}>
        {tagsOptions.map((tag, index) => (
          <Menu.Item
            key={tag.value}
            p={4}
            mb={index !== tagsOptions.length - 1 ? 4 : 0}
            onClick={() => handleTagToggle(tag.value)}
            closeMenuOnClick={false}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={selectedTags.includes(tag.value)}
                onChange={() => handleTagToggle(tag.value)}
                size="sm"
                color="indigo"
                style={{ marginRight: 10 }}
              />
              <Text
                size="sm"
                style={{
                  backgroundColor: `${tag.color}50`,
                  color: tag.color,
                  padding: "0px 4px",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                {tag.label}
              </Text>
            </div>
          </Menu.Item>
        ))}
        {tagsOptions.length == 0 && (
          <Menu.Item
            key={"create-new"}
            p={4}
            closeMenuOnClick={false}
          >
            <Text
              size="sm"
              color="#aeaeae"
              component="a"
              href={"/settings/tags"}
              target="_blank"
              p="sm"
              style={{
                color: "#5C5CEB",
              }}
            >
              Create new {col?.title?.toLowerCase()} +
            </Text>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
