import { ActionIcon } from "@mantine/core"
import { IconStar } from "@tabler/icons"
import { getBagelId } from "../../../../utils"
import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import { useState } from "react";
import { saveIdeaStarred } from "../../../../Api";

const StarCell: React.FC<any> = ({ data }) => {

  const auth0 = useAuth0();
  const [loadingStar, setLoadingStar] = useState([]);
  const [ideas, setIdeas] = useState<any[]>([]);
  const [starred, setstarred] = useState<boolean>(data?.starred &&
    auth0?.user &&
    data?.starred?.includes(getBagelId(auth0?.user)))

  const starClicked = (
    _id: string,
    bool: boolean,
    setLoadingStar: React.Dispatch<React.SetStateAction<any>>,
    idea: any,
    setIdeas: React.Dispatch<React.SetStateAction<any[]>>,
    auth0: Auth0ContextInterface<User>
  ) => {
    setLoadingStar((prev: any) => ({
      ...prev,
      [_id]: true,
    }));
    saveIdeaStarred(idea, auth0)
      .then(() => {
        setIdeas((prevState: any) => {
          const updated = prevState.map((item: any) => {
            if (item._id == _id) {
              const userId = getBagelId(auth0.user);
              return {
                ...item,
                starred: bool ? userId : false,
              };
            } else {
              return item;
            }
          });
  
          return updated;
        });
      })
      .catch(console.log)
      .finally(() =>{setLoadingStar((prev: any) => ({
        ...prev,
        [_id]: false,
      }))
      setstarred(!starred)
      }
        
      );
  };

  const  handleStarClicked = (_id: string, bool: boolean) => {
    /* setstarred(!setstarred) */
    starClicked(
      _id,
      bool,
      setLoadingStar,
      {
        id: _id,
        push: bool,
      },
      setIdeas,
      auth0
    );
  };


  return (
    <ActionIcon  loading={loadingStar[data?._id]} onClick={() => handleStarClicked(
      data._id,
      data.starred &&
          auth0.user &&
          data.starred?.includes(getBagelId(auth0.user))
        ? false
        : true
    )}>
      <IconStar
        color={
          starred
            ? "#FDAE1680"
            : "#D1D5DB"
        }
        fill={
          starred
            ? "#FDAE1680"
            : "#fff"
        }
        size={16}
        strokeWidth={2}
      />
    </ActionIcon>
  )
}

export default StarCell

