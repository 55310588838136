import { DataTable,  DataTableSortStatus } from 'mantine-datatable';
import { Stack, Tooltip, Text, Button, Flex } from '@mantine/core';
import { TextCell } from '../components/TableCells/TextCell';
import { OwnerCell } from '../components/TableCells/OwnerCell';
import { PriorityCell } from '../components/TableCells/PriorityCell';
import { TemplateCell } from '../components/TableCells/TemplateCell';
import { CommitmentsCell } from '../components/TableCells/CommitmentsCell';
import { DynamicCell } from '../components/TableCells/DynamicCell';
import { updateAccessorByState } from '../../../utils';
import React, { useContext, useEffect, useState } from 'react';
import SyncContext from '../../../context/SyncContext';
import IdeasContext from '../IdeasContext';
import { useNavigate } from 'react-router-dom';
import StarCell from '../components/TableCells/StarCell';
import NewRequestsCell from '../components/TableCells/NewRequestsCell';
import { TagsCell } from '../components/TableCells/TagsCell';
import { IconGripVertical } from '@tabler/icons';


export default function IdeasTable({
  ideaColumns,
  ideas,
  isAllowedToEditDates,
  handlePageChange,
  totalRecords,
  recordsPerPage,
  page,
  sortStatus,
  setSortStatus,
  loading,
  PAGE_SIZE,

}: {
  ideaColumns: any[];
  ideas: any[];
  isAllowedToEditDates: boolean;
  handlePageChange: (newPage: string) => void;
  totalRecords: number | undefined;
  recordsPerPage: number;
  page: number;
  sortStatus: DataTableSortStatus;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
  loading: boolean;
  PAGE_SIZE: number;
  
}) {

  const {
    requestPriorities,
    ideaPriorities,
    displayState,
    ideasTableDisplay,
    customEvidenceObj,
  } = useContext(SyncContext);

  const { sortState } = useContext(IdeasContext);

  const [columns, setColumns] = useState<any[]>([]);
 
  const navigate = useNavigate();
  const cellComponents: { [key: string]: React.FC<any> } = {
    TextCell,
    OwnerCell,
    PriorityCell,
    TemplateCell,
    CommitmentsCell,
    StarCell,
    DynamicCell,
    NewRequestsCell,
    TagsCell
    // Add other cell components as needed
  };

  const dealBreakerObj = requestPriorities.find(
    (r: any) => r.key === "Deal Breaker"
  ) || {
    key: "Deal Breaker",
    label: "Deal Breaker",
  };


  const handleOnRowClick = (ideaId: string) => {
    const queryParams = new URLSearchParams(location.search);
    const activeFilters = JSON.parse(queryParams.get("filters") || "[]");

    // Filter activeFilters to include only those whose parent is in evidenceParents
    const filteredFilters = activeFilters.filter((filter: any) =>
      filter.filterObject == "evidence"
    );

    // Serialize the filtered filters back into a string
    const filtersString = JSON.stringify(filteredFilters);

    // Construct the new URL with the filtered filters parameter if it exists and is not empty
    const url = `/idea/${ideaId}${
      filtersString ? `?filters=${encodeURIComponent(filtersString)}` : ""
    }`;

    // Save current filters in sessionStorage
    sessionStorage.setItem("lastFilters", location.search);

    // Navigate to the new URL
    navigate(url);
  };

  const onSortStatusChanged = (sortStatus: DataTableSortStatus) => {
    setSortStatus((prevStatus) => {
      if (prevStatus?.columnAccessor === sortStatus.columnAccessor) {
        return sortStatus;
      } else {
        return { columnAccessor: sortStatus.columnAccessor, direction: "asc" };
      }
    });
  };

  const draggIt = (draggedTitle: string, droppedTitle: string) => {
    setColumns((prevColumns) => {
      const draggedIndex = prevColumns.findIndex(col => col.title === draggedTitle);

      const droppedIndex = prevColumns.findIndex(col => col.title === droppedTitle);
  
      if (draggedIndex === -1 || droppedIndex === -1 || draggedIndex === droppedIndex) {
        
        return prevColumns;
      }
  
      const draggedColumn = prevColumns[draggedIndex];
      const filteredColumns = prevColumns.filter((_, index) => index !== draggedIndex); 
  
      const splicedCols = filteredColumns.toSpliced(droppedIndex, 0, draggedColumn);
  
      return splicedCols;
    });
  };

  const handleOnDrag = (e: React.DragEvent, draggedColTitle: string) => {
    e.dataTransfer.setData('title', draggedColTitle)
  }

  const handleOnDrop = (e: React.DragEvent, droppedColTitle: string)=>{
    const draggedCol = e.dataTransfer.getData('title') as string
    draggIt(draggedCol, droppedColTitle)
  }

  const handleDragOver = (e: React.DragEvent)=>{
    e.preventDefault()
  }

  useEffect(() => {

    setColumns(ideaColumns)
    
  }, [ideaColumns]);

  return (
    <Stack>
      <DataTable
        pinLastColumn
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          thead: {
            position: "sticky",
            top: 0,
            zIndex: 4,
            backgroundColor: "#F3F4F6",
          },
          "thead tr:first-of-type": {
            borderColor: "#dee2e6",
            fontSize: 12,
          },
          "thead th": {
            height: "48px",
            padding: "6px 10px !important", 
            fontSize: 12,
            textAlign: "center", 
            verticalAlign: "middle",
            backgroundColor: "#EFEFFD",
          },
          "tbody td": {
            height: "48px",
            /* padding: "6px 20px !important", */ 
            /* paddingLeft: '20px', */
            fontSize: 12,
            textAlign: "center", 
            verticalAlign: "middle",
          },
          "thead th:first-of-type": {
            position: "sticky",
            left: 0,
            zIndex: 5,
            backgroundColor: "#EFEFFD",
            borderRight: "1px solid #dee2e6",
          },
          "thead th:last-of-type": {
            position: "sticky",
            right: 0,
            zIndex: 5,
            backgroundColor: "#EFEFFD",
            borderLeft: "1px solid #dee2e6",
          },
          "tbody td:first-of-type": {
            position: "sticky",
            left: 0,
            zIndex: 3,
            backgroundColor: "white",
            borderRight: "1px solid #dee2e6",
          },
          "tbody td:last-of-type": {
            /* position: "sticky",
            right: 0, 
            zIndex: 2, */
            backgroundColor: "white",
            borderLeft: "1px solid #dee2e6",
          },
        
        }}
        sortStatus={sortStatus}
        onSortStatusChange={onSortStatusChanged}
        withBorder
        idAccessor={"_id"}
        storeColumnsKey={'Ideas-table'}
        records={ideas}
        columns={[...columns
          .filter((col: any) => col.enabled && !col.hidden)
          .sort((a: any, b: any) => a.index - b.index)
          .map((col: any) => {
           
            const CellComponent = cellComponents[col.renderComponent]?
              cellComponents[col.renderComponent] : cellComponents['TextCell']; 

            return {
              ...col,
              draggble: false,  
              accessor: col.renderComponent == 'DynamicCell' ? updateAccessorByState(col.accessor, sortState) : col.accessor ,
              title: 
              <div 
                style={{ cursor: 'grab' }}
                className={col.title} 
                draggable 
                onDragStart={(e)=> handleOnDrag(e, col?.title)}
                onDrop={(e)=>handleOnDrop(e, col?.title)}
                onDragOver={handleDragOver}
              > 
                <Flex>
                  <IconGripVertical size={14} color='#78909C'/>

                  <Tooltip label={col?.tooltip} disabled={!col?.tooltip}>
                    <Text ml={6}>{col?.title}</Text>
                  </Tooltip>
                </Flex>
               
              </div>
              ,
              width: col.width,
              sortable: col?.sortable,
              openIdeaOnCLick: col?.openIdeaOnCLick,
             
              resizable: false,
              render: (data: any) => (
                <CellComponent
                  data={data}
                  col={col}
                  renderParams={col.renderParams || {}} 
                  ideaPriorities={ideaPriorities}
                  dealBreakerObj={dealBreakerObj} 
                  customEvidenceObj={customEvidenceObj}
                  isAllowedToEditDates={isAllowedToEditDates}
                  ideasTableDisplay={ideasTableDisplay}
                  displayState={displayState}
                  accessor={col.accessor}
                />
              ),
            };
          })]}
        height={`calc(100vh - 250px)`}
        totalRecords={totalRecords}
        recordsPerPage={recordsPerPage}
        fetching={loading}
        page={page}
        onPageChange={(p) => handlePageChange(p.toString())}
        onCellClick={({ record: idea, column }: any) => {

          if (column?.openIdeaOnCLick === undefined || column?.openIdeaOnCLick){
            if (column?.renderComponent === 'StarCell') {
              return
            } else {
              handleOnRowClick(idea._id);
            }
          }
          
        }}
      />
    </Stack>
  );
} 
