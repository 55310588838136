import { createStyles, Button, Menu, Group, ActionIcon } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  button: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#5B60E3",
    ":hover": {
      backgroundColor: "#474bb0",
    },
    height: 32,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: 0,
    borderLeft: "1px solid #474bb0",
    backgroundColor: "#5B60E3",
    ":hover": {
      backgroundColor: "#474bb0",
    },
    height: 32,
    minHeight: 32,
  },
}));

interface SplitButtonProps {
  isSplit: boolean;
  onClick: () => void;
  disabled?: boolean | undefined;
  lable: string;
  items: any[];
}

export function SplitButton({
  isSplit,
  onClick,
  disabled,
  lable,
  items,
}: SplitButtonProps) {
  const { classes, theme } = useStyles();

  return (
    <Group noWrap spacing={0} mr={5.8}>
      <Button
        variant="default"
        color="gray"

        /* className={classes.button} */
        disabled={disabled}
        sx={
          !isSplit
            ? {fontSize: 13,
              fontWeight:500,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }
            : {fontSize: 13, fontWeight:500}
        }
        onClick={onClick}
      >
        {lable}
      </Button>
      {isSplit && (
        <Menu
          position="bottom-end"
          withinPortal
          shadow="sm"
          radius="md"
          withArrow
        >
          <Menu.Target>
            <ActionIcon
              variant="filled"
              color={theme.primaryColor}
              size={36}
              className={classes.menuControl}
            >
              <IconChevronDown size="1rem" stroke={1.5} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {items.map((item) => (
              <Menu.Item onClick={item.onClick} key={item.id}>
                {item.name}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
}
