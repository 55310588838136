import React, { useContext } from 'react';
import { Box, Group, TextInput, ActionIcon, Transition } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import IdeasContext from '../IdeasContext';

interface FilterPanelProps {
  toggle: () => void;
  search: string;
  setSearch: (search: string) => void;
  searchOpened: boolean;
  setSearchOpened: (opened: boolean) => void;  // Add this line in your interface
}

const SearchPanel: React.FC<FilterPanelProps> = ({ toggle, search, setSearch, searchOpened, setSearchOpened }) =>{

  return (
    <Box p={0}>
      <Group noWrap>
 
        <Group>
          <Transition mounted={searchOpened} transition="fade" duration={400} timingFunction="ease">
            {(styles) => (
              <div style={styles}>
                <TextInput
                  value={search}
                  onChange={(event) => {
                    setSearch(event.currentTarget.value)}
                  }
                  variant="filled"
                  size="xs"
                />
              </div>
            )}
          </Transition>
          <ActionIcon onClick={() => {
            setSearchOpened(!searchOpened)
          }}>
            <IconSearch size={18} />
          </ActionIcon>
        </Group>
      </Group>
    </Box>
  );

} 
     
export default SearchPanel;



{/* <Box mb="1.5px">
        <Tooltip label="Filters" withArrow>
          <ActionIcon onClick={toggle}>
            <IconFilter strokeWidth={2} size={22} color="black" />
          </ActionIcon>
        </Tooltip>
      </Box> */}