import { Badge, Box, Group, Tooltip } from "@mantine/core"
import { SourceIcon } from "../../../../utils/SourceIcon";

const NewRequestsCell: React.FC<any> = ({ data}:{data: any}) => {

  const newCount = data?.aggregations?.requests?.new || 0;

  return (
    <Group spacing="xs" noWrap ml={39}>
      {newCount > 0 && (
        <Tooltip
          position="bottom"
          sx={{ fontSize: 12 }}
          label={`${newCount} new evidences`}
        >
          <Badge variant="dot">{newCount}</Badge>
        </Tooltip>
      )}
      {/* Additional content can be added here */}
      {/*  {origin && (
        <Tooltip
          position="bottom"
          sx={{ fontSize: 12 }}
          label={`${origin} sync - ${originId}`}
        >
          <Box mb={-2} p={0}>
            <SourceIcon sourceName={origin} width={16} height={16} />
          </Box>
        </Tooltip>
      )} */}
    </Group>
  )
}

export default NewRequestsCell