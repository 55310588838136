import { Flex, Text } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { IconAlertOctagon } from "@tabler/icons";

// PriorityCell Component
export const PriorityCell: React.FC<any> = ({ data, ideaPriorities, col }) => {
  const priority = getNestedFieldValue(data, col.accessor);
  const defaultPriority = ideaPriorities?.find((p: any) => p?.isDefault);
  const selectedPriority = priority
    ? ideaPriorities?.find(
      (p: any) =>
        p?.key?.toLowerCase() === priority?.toLowerCase() ||
            p?.label?.toLowerCase() === priority?.toLowerCase()
    )
    : {
      color: "#ccc",
      index: 1,
      key: "Unassigned",
      label: "Unassigned",
    };
  
  return (
    <Flex align='center' ml={14}>
      
      <Flex sx={{backgroundColor: '#FFFAF2'}} p={3}>
        <IconAlertOctagon color="#FF8700" size={14} />
        <Text color='#FF8700' ml={4}>
          {selectedPriority?.label || defaultPriority?.label || "Unassigned"}
        </Text>
      </Flex>
      
    </Flex>
  );
};