import { Box, Flex } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { OverflownText } from "../../../OverflownText";
import { ProductAreaIcon } from "../../../../icons/x-symbol-svgrepo-com";

export const TextCell: React.FC<any> = ({ data, col, renderParams }) => {
  const value = getNestedFieldValue(data, col.accessor) || renderParams.default

  return (
    <Box
      sx={{
        maxWidth: renderParams.maxWidth || "350px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        /* backgroundColor: 'black' */
      }}
    >
      <Flex>
        {col.accessor === 'componentObj.name'  &&
        <Box mr={4.2}>
          <ProductAreaIcon color="#212529" />
        </Box>
        
        }
        <OverflownText
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginLeft: col.accessor === 'status' ? '10px' : '0px'
          }}
          overTooltip={renderParams.tooltip}
          {...renderParams}
        >
          {value}
        </OverflownText>
      </Flex>
      
    </Box>
  );
};