import { Tooltip, Text, TextProps } from "@mantine/core";
import { useRef, useState, useEffect } from "react";

interface OverflownTextProps extends TextProps {
  overTooltip?: string;
}

export function OverflownText({
  children,
  overTooltip,
  ...props
}: OverflownTextProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(
      element
        ? element.clientWidth < element.scrollWidth ||
          element.offsetHeight < element.scrollHeight
        : false
    );
  }, [children]);

  return (
    <Tooltip
      sx={{ whiteSpace: "break-spaces" }}
      label={overTooltip || children}
      disabled={!isOverflown && !overTooltip}
      maw="50vw"
      multiline
    >
      <Text color="#212529" ref={ref} {...props} fz={13.2}>
        {children}
      </Text>
    </Tooltip>
  );
}