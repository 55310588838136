import React, { useContext, useEffect, useState } from "react";
import {
  Collapse,
  Group,
  ActionIcon,
  TextInput,
  Flex,
  Checkbox,
  Text,
  Box
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import { SplitButton } from "../../general/SplitButton";
import IdeasContext from "../IdeasContext";
import { showNotification } from "@mantine/notifications";
import FiltersMenuWrapper from "./FiltersMenuWarper";
import SearchPanel from "./SearchPanel";

interface FilterActionsProps {
  activeFilters: any[];
  setActiveFilters: (filters: any[]) => void;
  selectedView: any;
  open: () => void;
  handleSaveViews: () => void;
  opened: boolean;
  viewName: string;
  setViewName: (name: string) => void;
  doClose: () => void;
  toggle: () => void;
  search: string;
  setSearch: (search: string) => void;
  searchOpened: boolean;
  setSearchOpened: (opened: boolean) => void;
  actionIconsRef: React.MutableRefObject<any>
}

const FilterActions: React.FC<FilterActionsProps> = ({
  activeFilters,
  setActiveFilters,
  selectedView,
  open,
  handleSaveViews,
  opened,
  viewName,
  setViewName,
  doClose,
  toggle,
  search,
  setSearch,
  searchOpened,
  setSearchOpened,
  actionIconsRef
}) => {
  const { upsertView, sortState, setSortState } = useContext(IdeasContext);

  const [value, setValue] = useState<string[]>(['assigned', 'suggested']);

  useEffect(() => {
    if(value.length === 2 || value.length === 0){
      setSortState('total')
    }

    else if(value.length === 1){
      setSortState(value[0])
    }
  }, [value]); 

  return (
    <Collapse in={true} my={12}>
      <Group mx="sm" /* mb="sm" */ my={18}  position="apart"  noWrap align="center">
        <Flex>
          <Group /* spacing={8} */>
            <FiltersMenuWrapper
              /* menuFiltersObj={menuFiltersObj} */
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              selectedView={selectedView}
              MenuType="idea"
            />

            <FiltersMenuWrapper
              /* menuFiltersObj={menuFiltersObj} */
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              selectedView={selectedView}
              MenuType="evidence"
              /* targetSx={{
                marginLeft: 1
              }} */
            />
          </Group>
          <Group spacing={4} noWrap mx='md'>
            <Box ref={actionIconsRef} mr={5}>
              <SearchPanel
                toggle={toggle}
                search={search}
                setSearch={setSearch}
                searchOpened={searchOpened}
                setSearchOpened={setSearchOpened}
              />
            </Box>
            <SplitButton
              
              isSplit={!selectedView?.isGlobal && !opened}
              lable={
                selectedView?.isGlobal && !opened
                  ? "Save as new view"
                  : !selectedView?.isGlobal
                    ? "Save view"
                    : "Save"
              }
              onClick={() => {
                console.log({
                  selectedView,
                  elseif: !selectedView?.isGlobal && !opened,
                });
                if (selectedView?.isGlobal && !opened) {
                  open();
                } else if (opened) {
                  handleSaveViews();
                } else if (!selectedView?.isGlobal && !opened) {
                  upsertView(
                    { _id: selectedView._id, filters: activeFilters },
                    (res) => {
                      console.log(res);
                      showNotification({
                        title: "Saved successfully!",
                        color: "teal",
                        message: "View saved successfully",
                        icon: <IconCheck size="1.1rem" />,
                        autoClose: 1200
                      });
                    }
                  );
                }
              }}
              items={[
                {
                  id: "1",
                  onClick: () => open(),
                  name: "Save as new view",
                },
              ]}
            /> 

            {/* New view name input */}
            {opened && (
              <>
                <TextInput
                  value={viewName}
                  onChange={(event) => setViewName(event.currentTarget.value)}
                  placeholder="View name"
                  size="xs"
                />
                <ActionIcon sx={{ height: 32 }} onClick={doClose}>
                  <IconX size={14} />
                </ActionIcon>
              </>
            )}



          </Group> 
        </Flex>
        <Flex align='center'>

          
        
          <Flex /* mr={80} */>
            <Checkbox.Group
              value={value}
              onChange={(values: string[]) => {
                if (values.length > 0) setValue(values);
              }}
            >
              <Flex gap="md"> {/* Wrap the checkboxes in a Flex with horizontal gap */}
                <Checkbox 
                  color="indigo" 
                  value='assigned' 
                  label={
                    <Flex align='center'>
                      <Box
                        sx={{
                          width: 5.8,
                          height: 5.8,
                          borderRadius: '50%',
                          backgroundColor: "#5C5CEB",
                        }}
                        ml={-3}
                        mr={8}
                      />
                      <Text color="#5C5CEB" mt={-1}>Assigned</Text>
                    </Flex>
                  }
                />

                <Checkbox 
                  color="indigo" 
                  value='suggested' 
                  label={
                    <Flex align='center'>
                      <Box
                        sx={{
                          width: 5.8,
                          height: 5.8,
                          borderRadius: '50%',
                          backgroundColor: "rgba(92, 92, 235, 0.6)",
                        }}
                        ml={-3}
                        mr={8}
                      />
                      <Text color="rgba(92, 92, 235, 0.8)" mt={-1}>Suggested</Text>
                    </Flex>
                  }
                />
              </Flex>
            </Checkbox.Group>
          </Flex>

        </Flex>
        
      </Group>
    </Collapse>
  );
};

export default FilterActions;
