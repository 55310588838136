import { IconFileExport, IconTag } from "@tabler/icons";
import { AccountNivIcon, AccountNivSelectedIcon, DiscoveryIcon, DiscoveryIconSelected, IdeasIcon, IdeasIconSelected, ImportNivIcon, ImportSelectedNivIcon, IntegrationsNivIcon, IntegrationsSelectedNivIcon, MembersNivIcon, MembersNivSelectedIcon, ObjectsFieldsNivIcon, ObjectsFieldsSelectedNivIcon, ProductAreaNivIcon, ProductAreaNivSelectedIcon, ReportsIcon, ReportsIconSelected, RequestsIcon, RequestsIconSelected, TeamsNivIcon, TeamsNivSelectedIcon } from "../../icons/x-symbol-svgrepo-com";

export const showTopReports = window.__RUNTIME_CONFIG__.REACT_APP_SHOW_TOP_REPORT === "true";
export const mainLinks = [
  { link: "/discovery", label: "Discovery", icon: DiscoveryIcon, activeIcon: DiscoveryIconSelected, disabled: false },
  { link: "/inbox", label: "Inbox", icon: RequestsIcon, activeIcon: RequestsIconSelected, disabled: false },
  { link: "/ideas", label: "Ideas", icon: IdeasIcon, activeIcon: IdeasIconSelected, disabled: false },
  // {
  //   link: "/reports",
  //   label: "Reports",
  //   icon: ReportsIcon,
  //   activeIcon: ReportsIconSelected,
  //   disabled: !showTopReports,
  // },
];

export const settingsLinks = [
  { link: "/settings/account", label: "Account", icon: AccountNivIcon, activeIcon: AccountNivSelectedIcon },
  { link: "/settings/members", label: "Members", icon: MembersNivIcon, activeIcon: MembersNivSelectedIcon},
  { link: "/settings/teams", label: "Teams", icon: TeamsNivIcon, activeIcon: TeamsNivSelectedIcon },
  {
    link: "/settings/productareas",
    label: "Product area",
    icon: ProductAreaNivIcon, activeIcon: ProductAreaNivSelectedIcon
  },
  {
    link: "/settings/tags",
    label: "Tags",
    icon: IconTag, activeIcon: IconTag
  },
  { link: "/settings/objects", label: "Objects & fields", icon: ObjectsFieldsNivIcon , activeIcon: ObjectsFieldsSelectedNivIcon},
  {
    link: "/settings/integrations",
    label: "Integrations",
    icon: IntegrationsNivIcon, activeIcon: IntegrationsSelectedNivIcon
    
  },
  {
    link: "/settings/import",
    label: "Import",
    icon: ImportNivIcon, activeIcon: ImportSelectedNivIcon,
    children: [
      {
        link: "/settings/import/ideas_evidence",
        label: "Ideas and evidence",
      },
      {
        link: "/settings/import/items",
        label: "Potential evidence",
      },
    ],
  },
  {
    link: "/settings/export",
    label: "Export",
    icon: (props: any) => IconFileExport, 
    activeIcon: ImportSelectedNivIcon,
    children: [
      {
        link: "/settings/export/ideas",
        label: "Ideas",
      },
      {
        link: "/settings/export/items",
        label: "Evidence",
      },
    ],
  },
];
